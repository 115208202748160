import React from "react";
import AvantageCard from "../../Components/AvantageCard";
import { makeStyles } from "@material-ui/core/styles";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { Typography } from "@material-ui/core";
import logicielImg from "./logiciel.png";
import logiciels_fr from "../../languages/fr/infos_logiciels.fr.json";
import logiciels_en from "../../languages/en/infos_logiciels.en.json";
import logiciels_ar from "../../languages/ar/infos_logiciels.ar.json";
import { useIntl } from "react-intl";

const useStyles = makeStyles({
  topContainer: {
    height: "70vh",
    maxWidth: "100%",
    backgroundColor: "#020611",
  },
  imageContainer: {
    backgroundImage: `url(${logicielImg})`,
    backgroundSize: "cover",
    width: "48vw",
    height: "80vh",
    position: "absolute",
    top: "12vh",
    right: "0vw",
    "@media(max-width: 810px)": {
      display: "none",
    },
  },
  title: {
    color: "white",
    marginTop: "20vh",
    fontSize: "50px",
    marginLeft: "12%",

    "@media(max-width: 810px)": {
      marginTop: "5vh",
      display: "block",
    },
  },
  button: {
    color: "white",
    backgroundColor: "#0451e1",
    marginLeft: "14vw",
    marginTop: "15vh",
    fontSize: "20px",
    "@media(max-width: 810px)": {
      maxWidth: "100rem",
      display: "block",
      marginRight: "50px",
    },
  },
  secondContainer: {
    backgroundColor: "#F2F5F6",
    width: "100%",
    height: "52vh",
    display: "flex",
    "@media(max-width: 1313px)": {
      height: "145vh",
    },
    "@media(max-width: 774px)": {
      height: "225vh",
    },
  },
  avantageContainer: {
    display: "flex",
    height: "50vh",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    flexWrap: "wrap",
    "@media(max-width: 1313px)": {
      height: "120vh",
    },
    "@media(max-width: 774px)": {
      height: "200vh",
    },
  },

  descriptionContainer: {
    width: "70%",
    marginLeft: "12%",
    marginTop: "140px",
    marginBottom: "160px",
    fontSize: "18px",
  },
});
export default function Logiciel(props) {
  const classes = useStyles();

  const intl = useIntl();
  let softwares;
  const language = intl.locale;

  if (language === "fr-FR") {
    softwares = logiciels_fr;
  } else if (language === "en-US") {
    softwares = logiciels_en;
  } else if (language === "ar-001") {
    softwares = logiciels_ar;
  } else softwares = logiciels_fr;

  return (
    <div>
      {softwares?.map((data) => {
        if (props.match.params.id === data.id)
          return (
            <>
              <div className={classes.topContainer}>
                <div style={{ paddingTop: "10vh" }}>
                  <Zoom
                    right
                    cascade
                    collapse
                    className={{
                      marginTop: "16vh",
                      display: "flex",
                      flexDirection: "center",
                    }}
                  >
                    <Typography className={classes.title}>
                      {data.name}{" "}
                    </Typography>
                  </Zoom>
                </div>
                <div className={classes.imageContainer} />
              </div>
              <Fade>
                <div className={classes.descriptionContainer}>
                  {data.page_descriptions.map((description) => (
                    <>
                      <p>{description.text}</p>
                      {description?.bulletPoints ? (
                        <ul>
                          {description.bulletPoints.map((point) => (
                            <li>{point}</li>
                          ))}
                        </ul>
                      ) : null}
                    </>
                  ))}
                </div>
              </Fade>
              {data.screenshot1!=null &&
                <div className={classes.secondContainer}>
                  <div className={classes.avantageContainer}>
                    <AvantageCard
                      image={`/Assets/logiciel_cards/${data.screenshot1}`}
                      />
                    <AvantageCard
                      image={`/Assets/logiciel_cards/${data.screenshot2}`}
                      />
                    <AvantageCard
                      image={`/Assets/logiciel_cards/${data.screenshot3}`}
                      />
                  </div>
                </div>
              }
            </>
          );
      })}{" "}
    </div>
  );
}
